<template>
  <div class="p-home">
    <div class="p-home__banner">
      <c-swiper :banner="banner"></c-swiper>
    </div>
    <div class="p-home__product">
      <div class="p-home__product__develop container-fluid">
        <c-home-title :data="titleArr[0]"></c-home-title>
        <div class="p-home__product__briefly container">
          <div class="p-home__product__wrapper">
            <div class="row">
              <c-home-product-item 
              @goLink="bindGoLink"
              v-for="(item, index) in productArr"
              :data="item"
              :key="item.title + index"
              ></c-home-product-item>
            </div>
          </div>
        </div>
        <div class="p-home__product__detail container-fluid">
          <c-home-product-detail
          v-for="(item, index) in detailArr"
          :data="item"
          :key="item.title + index"
          :isNormal="index % 2"
          ></c-home-product-detail>
        </div>
      </div>
    </div>
    <div 
    class="p-home__service" 
    :style="{'background-image': `url(${service.bg})`}">
      <div class="p-home__service__wrapper">
        <div class="p-home__service__title">
          {{service.title}}
        </div>
        <div class="p-home__service__text">
          {{service.text}}
        </div>
      </div>
    </div>
    <div class="p-home__strength need-ani">
      <div class="container">
        <c-home-title :data="titleArr[1]"></c-home-title>
        <div class="p-home__strength__wrapper row">
          <div 
          class="p-home__strength__book col-xs-12 col-sm-6 col-md-3 col-lg-3 left-flip"
          v-for="(item, index) in strengthArr"
          :key="item.src + index">
            <div class="p-home__strength__book__img">
              <img :src="item.src" alt="">
            </div>
            <div class="p-home__strength__book__text">
              {{item.text}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="p-home__partner need-ani">
      <div class="container">
        <c-home-title :data="titleArr[2]"></c-home-title>
        <div class="p-home__partner__wrapper row">
          <div 
          class="p-home__partner__logo col-xs-12 col-sm-4 col-md-2 col-lg-2 right-rotate"
          v-for="(item, index) in partnerArr"
          :key="item.src + index">
            <img :src="item.src" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import homeBanner0 from '../../assets/images/home/home_banner0.png'
import homeBanner1 from '../../assets/images/home/home_banner1.png'
import icon0 from '../../assets/images/home/icon_0.png'
import icon1 from '../../assets/images/home/icon_1.png'
import icon2 from '../../assets/images/home/icon_2.png'
import icon3 from '../../assets/images/home/icon_3.png'
import icon4 from '../../assets/images/home/icon_4.png'
import icon5 from '../../assets/images/home/icon_5.png'
import detail0 from '../../assets/images/home/detail_0.png'
import detail1 from '../../assets/images/home/detail_1.png'
import detail2 from '../../assets/images/home/detail_2.png'
import service from '../../assets/images/home/service.png'
import strength0 from '../../assets/images/home/strength_0.png'
import strength1 from '../../assets/images/home/strength_1.png'
import strength2 from '../../assets/images/home/strength_2.png'
import strength3 from '../../assets/images/home/strength_3.png'
import partner0 from '../../assets/images/home/partner_0.png' 
import partner1 from '../../assets/images/home/partner_1.png' 
import partner2 from '../../assets/images/home/partner_2.png' 
import partner3 from '../../assets/images/home/partner_3.png' 
import partner4 from '../../assets/images/home/partner_4.png' 
import partner5 from '../../assets/images/home/partner_5.png' 
import cHomeTitle from './components/c-home-title.vue'
import cHomeProductItem from './components/c-home-product-item.vue'
import cHomeProductDetail from './components/c-home-product-detail.vue'
export default {
  data() {
    return {
      banner: [
        {src: homeBanner0},
        {src: homeBanner1}
      ],
      titleArr: [
        {title: '开发内容', text: '优质服务    专业团队    高度定制    快速交付    售后无忧'},
        {title: '企业实力', text: '多元化的服务　全面的技术　一流软件开发站点'},
        {title: '合作伙伴', text: '他们都信任龙火科技，并一起合作共赢'}
      ],
      productArr: [
        {icon: icon0, title: '即时聊天', text: '支持群聊，视频语音，好友圈管理， 适用各平台APP', src: ''},
        {icon: icon1, title: '积分云', text: '一机支持40多家银行，直联银行积分 系统', src: 'http://jf.loofire.com/'},
        {icon: icon2, title: '中和云', text: '助力银行提高持卡用户活跃性，互利 多赢', src: 'http://pos.loofire.com/'},
        {icon: icon3, title: '优粉云', text: '赋能短视频营销，助力抢占市场，实 现流量裂变', src: 'http://ufanscloud.com/'},
        {icon: icon4, title: '线上商城', text: '助力企业构建私域流量进入品牌电商 新时代', src: ''},
        {icon: icon5, title: '钱包系统', text: '为产品搭建钱包系统，功能齐全，安 全可靠', src: ''}
      ],
      detailArr: [
        {src: detail0, title: '积分云', text: '积分快速变现，信用卡积分兑换专用终端机，全国独家，合规放心。 全自动智能操作。 '},
        {src: detail1, title: '中和云POS', text: 'POS+积分兑换一体机，支持刷卡、扫码、快捷、云闪付等收款方式 与信用卡积分兑换，方便快捷。'},
        {src: detail2, title: '优粉云', text: '手机远程远程群控，在线发布任务，精准引流。三大核心赋能短视频 营销，全自动批量模拟人工操作。'}
      ],
      service: {
        bg: service,
        title: '产品服务',
        text: '提供多类型通讯、视频、云控、信用卡积分、交易支付等功能， 全面满足业务需求。'
      },
      strengthArr: [
        {src: strength0, text: '国家高新证书'},
        {src: strength1, text: '龙火科技网文证书'},
        {src: strength2, text: '国家增值电信业务经营许可'},
        {src: strength3, text: '软件企业证明函'}
      ],
      partnerArr: [
        {src: partner0},
        {src: partner1},
        {src: partner2},
        {src: partner3},
        {src: partner4},
        {src: partner5}
      ]
    }
  },
  created(){

  },
  mounted() {

  },
  methods: {
    bindGoLink(src) {
      console.log(src);
      src && window.open(src, '_blank'); 
    }
  },
  components: {
    cHomeTitle,
    cHomeProductItem,
    cHomeProductDetail
  },
  destroyed() {

  }
}
</script>
<style lang="scss" scoped>
@import "@/scss/var.scss";
.p-home {
  &__product {
    &__briefly {
      overflow: hidden;
      margin-bottom: 36px;
    }
    &__wrapper {
      position: relative;
      left: 1px;
      top: 1px;
    }
  }
  &__service {
    height: 320px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color1;
    &__title {
      font-size: $fs-title-s;
      line-height: 24px;
      margin-bottom: 16px;
    }
    &__text {
      font-size: $fs-text-m;
    }
  }
  &__strength {
    &__book {
      &__img {
        margin-bottom: 20px;
      }
      &__text {
        font-size: $fs-text-m;
        color: $color2;
        line-height: 24px;
      }
    }
  }
  &__partner {
    margin-bottom: 100px;
  }
}
</style>