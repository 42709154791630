<template>
<div class="c-home-title">
  <div class="c-home-title__title">{{data.title}}</div>
  <div class="c-home-title__text">{{data.text}}</div>
</div>
</template>

<script type="text/javascript">
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {

    }
  },
  created(){

  },
  mounted() {

  },
  destroyed() {

  }
}
</script>
<style lang="scss" scoped>
@import "@/scss/var.scss";
.c-home-title {
  text-align: center;
  margin: 36px 0px;
  &__title {
    font-size: $fs-title-s;
    color: $color2;
    line-height: $fs-title-s;
    margin-bottom: 12px;
  }
  &__text {
    font-size: $fs-text-s;
    color: $color4;
  }
}
</style>