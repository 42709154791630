var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-home-product-detail need-ani",style:({'background-color': !_vm.isNormal ? '#EDF0F5' : '#FFFFFF'})},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[(!_vm.isNormal)?_c('div',{staticClass:"c-home-product-detail__normal clearfix",style:({'padding': _vm.isPadding ? '0px' : ("0px " + _vm.mPadding + "px")})},[_c('div',{staticClass:"c-home-product-detail-left col-xs-12 col-sm-6 col-md-6 col-lg-6 left-displacement",style:({'padding-left': _vm.isPadding ? '86px' : '0px'})},[(_vm.isPadding)?_c('div',{staticClass:"c-home-product-detail__block"},[_c('img',{attrs:{"src":_vm.data.src,"alt":""}})]):_vm._e(),_c('div',{staticClass:"c-home-product-detail__wrapper",style:({
            position: _vm.isPadding ? 'absolute' : 'relative',
            left: _vm.isIE ? '86px' : '',
            transform: !_vm.isPadding ? 'translateY(0px)' : 'translateY(-50%)'
          })},[_c('div',{staticClass:"c-home-product-detail__title"},[_vm._v(" "+_vm._s(_vm.data.title)+" ")]),_c('div',{staticClass:"c-home-product-detail__text"},[_vm._v(" "+_vm._s(_vm.data.text)+" ")])])]),_c('div',{staticClass:"c-home-product-detail-right hidden-xs col-sm-6 col-md-6 col-lg-6 right-displacement",style:({'padding-right': _vm.isPadding ? '200px' : '0px'})},[_c('img',{staticClass:"pull-right",attrs:{"src":_vm.data.src,"alt":""}})])]):_c('div',{staticClass:"c-home-product-detail__reverse clearfix",style:({'padding': _vm.isPadding ? '0px' : ("0px " + _vm.mPadding + "px")})},[_c('div',{staticClass:"c-home-product-detail-left hidden-xs col-sm-6 col-md-6 col-lg-6 left-displacement",style:({'padding-left': _vm.isPadding ? '86px' : '0px'})},[_c('img',{staticClass:"pull-left",attrs:{"src":_vm.data.src,"alt":""}})]),_c('div',{staticClass:"c-home-product-detail-right col-xs-12 col-sm-6 col-md-6 col-lg-6 right-displacement",style:({'padding-right': _vm.isPadding ? '200px' : '0px'})},[(_vm.isPadding)?_c('div',{staticClass:"c-home-product-detail__block"},[_c('img',{attrs:{"src":_vm.data.src,"alt":""}})]):_vm._e(),_c('div',{staticClass:"c-home-product-detail__wrapper",style:({
            position: _vm.isPadding ? 'absolute' : 'relative',
            left: _vm.isIE ? '0px' : '',
            transform: !_vm.isPadding ? 'translateY(0px)' : 'translateY(-50%)'
          })},[_c('div',{staticClass:"c-home-product-detail__title"},[_vm._v(" "+_vm._s(_vm.data.title)+" ")]),_c('div',{staticClass:"c-home-product-detail__text"},[_vm._v(" "+_vm._s(_vm.data.text)+" ")])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }