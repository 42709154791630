<template>
<div class="c-home-product-detail need-ani"  :style="{'background-color': !isNormal ? '#EDF0F5' : '#FFFFFF'}">
  <div class="container">
    <div class="row">
      <div 
      class="c-home-product-detail__normal clearfix" 
      v-if="!isNormal"
      :style="{'padding': isPadding ? '0px' : `0px ${mPadding}px`}">
        <div 
        class="c-home-product-detail-left col-xs-12 col-sm-6 col-md-6 col-lg-6 left-displacement"
        :style="{'padding-left': isPadding ? '86px' : '0px'}">
          <div class="c-home-product-detail__block"  v-if="isPadding">
            <img :src="data.src" alt="">
          </div>
          <div class="c-home-product-detail__wrapper" 
          :style="{
            position: isPadding ? 'absolute' : 'relative',
            left: isIE ? '86px' : '',
            transform: !isPadding ? 'translateY(0px)' : 'translateY(-50%)'
          }">
            <div class="c-home-product-detail__title">
              {{data.title}}
            </div>
            <div class="c-home-product-detail__text">
              {{data.text}}
            </div>
          </div>
        </div>
        <div 
        class="c-home-product-detail-right hidden-xs col-sm-6 col-md-6 col-lg-6 right-displacement"
        :style="{'padding-right': isPadding ? '200px' : '0px'}">
          <img :src="data.src" alt="" class="pull-right">
        </div>
      </div>
      <div 
      class="c-home-product-detail__reverse clearfix" 
      v-else
      :style="{'padding': isPadding ? '0px' : `0px ${mPadding}px`}">
        <div 
        class="c-home-product-detail-left hidden-xs col-sm-6 col-md-6 col-lg-6 left-displacement"
        :style="{'padding-left': isPadding ? '86px' : '0px'}">
          <img :src="data.src" alt="" class="pull-left">
        </div>
        <div 
        class="c-home-product-detail-right col-xs-12 col-sm-6 col-md-6 col-lg-6 right-displacement"
        :style="{'padding-right': isPadding ? '200px' : '0px'}">
          <div class="c-home-product-detail__block" v-if="isPadding">
            <img :src="data.src" alt="">
          </div>
          <div class="c-home-product-detail__wrapper" 
          :style="{
            position: isPadding ? 'absolute' : 'relative',
            left: isIE ? '0px' : '',
            transform: !isPadding ? 'translateY(0px)' : 'translateY(-50%)'
          }">
            <div class="c-home-product-detail__title">
              {{data.title}}
            </div>
            <div class="c-home-product-detail__text">
              {{data.text}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script type="text/javascript">
import {getViewPort} from '../../../utils/index.js'
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    },
    isNormal: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isPadding: true
    }
  },
  created(){
    this.setPaddingByWindowSize();
    window.addEventListener('resize', this.setPaddingByWindowSize);
  },
  mounted() {

  },
  methods: {
    setPaddingByWindowSize() {
      let width = getViewPort().width;
      if(width <= 970) {
        this.isPadding = false;
      } else {
        this.isPadding = true;
      }
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.setPaddingByWindowSize);
  }
}
</script>
<style lang="scss" scoped>
@import "@/scss/var.scss";
.c-home-product-detail {
  padding: 65px 0px;
  box-sizing: border-box;
  &__wrapper {
    position: absolute;
    text-align: left;
    top: 50%;
    transform: translateY(-50%);
  }
  &__block {
    visibility: hidden;
  }
  &__title {
    font-size: $fs-title-s;
    color: $color2;
    margin-bottom: 16px;
  }
  &__text {
    max-width: 470px;
    font-size: $fs-text-m;
    color: $color3;
    line-height: 24px;
  }
  &__normal {
    .c-home-product-detail {
      &-left {
        padding-left: 86px;
        position: relative;
        display: flex;
        align-items: center;
      }
      &-right {
        padding-right: 200px;
        position: relative;
      }
    }
  }
  &__reverse {
    .c-home-product-detail {
      &-left {
        padding-left: 200px;
        position: relative;
      }
      &-right {
        padding-right: 86px;
        display: flex;
        align-items: center;
        position: relative;
      }
    }
  }
}
</style>