<template>
<div 
class="c-home-product-item col-xs-12 col-sm-6 col-md-4 col-lg-4" 
:style="{padding: padding}"
@click="$emit('goLink',data.src)">
  <div class="c-home-product-item__wrapper">
    <div>
      <div class="c-home-product-item__icon">
        <img :src="data.icon" alt="">
      </div>
      <div class="c-home-product-item__title">{{data.title}}</div>
      <div class="c-home-product-item__text">{{data.text}}</div>
    </div>
  </div>
</div>
</template>

<script type="text/javascript">
import {getViewPort} from '../../../utils/index.js'
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      padding: '16.66666% 0px'
    }
  },
  created(){
    this.setPaddingByWindowSize();
    window.addEventListener('resize', this.setPaddingByWindowSize);
  },
  mounted() {

  },
  methods: {
    setPaddingByWindowSize() {
      let width = getViewPort().width;
      if(width >= 1200) {
        this.padding = '16.66666% 0px'
      } else if(width >= 970) {
        this.padding = '16.66666% 0px'
      } else if(width >= 750) {
        this.padding = '25% 0px'
      } else {
        this.padding = '50% 0px'
      }
    },
    // bindGoLink(src) {
    //   this.$emit('goLink', src);
    // }
  },
  destroyed() {
    window.removeEventListener('resize', this.setPaddingByWindowSize);
  }
}
</script>
<style lang="scss" scoped>
@import "@/scss/var.scss";
.c-home-product-item {
  text-align: center;
  position: relative;
  border: 1px solid $color7;
  border-left: none;
  border-top: none;
  cursor: pointer;
  &__wrapper {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__icon {
    img {
      width: 100px;
      height: 100px;
    }
    margin-bottom: 16px;
  }
  &__title {
    font-size: $fs-title-s;
    color: $color3;
    margin-bottom: 16px;
  }
  &__text {
    font-size: $fs-text-m;
    color: $color4;
    line-height: 24px;
    max-width: 256px;
    margin: 0 auto;
  }
}
</style>